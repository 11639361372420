@import "rsuite/dist/rsuite.css";

/* #root{ padding: 10px; } */
/* Slider */

/* .slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
} */
/* .w-100 {
  width: 100%!important;
  height: 560px;
  object-fit: cover;
} */

/* .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
} */

/* .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
} */
/* li.list-group-item.list-group-item-dark:hover {
  color: red !important;
  background-color: yellow;
} */

/* .my-Btn{
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.my-Btn:hover {
  background-color: #555;

} */
* {
  font-family: "Montserrat", sans-serif !important;
}

.slid-img1 img {
  border: 1px solid rgb(204, 204, 204);
  cursor: pointer;
  height: 93px !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 80% 80%;
  color: white !important;
  background-color: #0f0b59;
  border-radius: 50%;
}

#root {
  overflow-x: hidden;
}

.container-fluid.img-slider {
  background: url("../images/Group.png.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0.1;
}

img.med.st-zoom-in {
  height: 239px;
  object-fit: fill;
}

h2 {
  text-align: center;
  padding: 20px;
}

.slider-padding {
  padding: 0px 0px 32px 0px;
}

.gap2 {
  margin-top: -70px;
}

section#about p {
  line-height: 25px;
  margin-top: 15px;
}

img.footer-logo {
  width: 35%;
}

/* .slider-padding {
  padding: 20px 0 20px 0;
} */
h1.about-padding.mt-5.text-center {
  padding-bottom: 5px;
  font-weight: 800;
  margin-top: 90px !important;
  font-size: 64px;
  color: #575757;
}

h2.blog-padding.st-section-heading-title {
  padding-top: 23px;
  font-weight: 600;
}

h1.header-align {
  text-align: center;
}

li.list-group-item:hover {
  color: #0cb8b6 !important;
  padding-left: 30px !important;
  transition: 0.5s !important;
}

nav.navbar.navbar-expand-lg.bg-body-tertiary.py-2.shadow.position-sticky {
  position: relative;
  z-index: 1;

}


.navbar-brand{
  width: 100px;
}
.nav_logo{
width: 100%;
height: 10%;

}

nav.navbar.navbar-expand-lg.bg-body-tertiary.py-4.shadow.position-sticky {
  z-index: 9999 !important;
}

.navbar .input-group .form-control:focus {
  outline: transparent !important;
  border: transparent !important;
  box-shadow: none !important;
}

.navbar input.form-control {
  border-radius: 0px 20px 20px 0px !important;
}

li.category-head.active.fw-bolder.fs-3.categories-padding {
  background-color: transparent;
  color: white;
  padding: 18px;
}

.st-post-info {
  background: white !important;
}

section#blog {
  background: url(../images/15002.webp);
  background-size: cover;
  height: 100vh;
}

.category1 {
  background-color: #0f0b59 !important;
  height: calc(100vh - 152.58px);
  overflow-y: auto;
  overflow-x: hidden;
}

.fw-bold.para-padding.text-center {
  padding-bottom: 15px;
}

li.category-head.active.fw-bolder.fs-3.categories-padding:hover {
  color: white;
}

svg.svg-align {
  margin-top: 7px;
  margin-left: 8px;
}

.input-function.input-group.me-3 {
  border: #0cb8b6 2px solid;
}

.search-align {
  right: 60px;
  top: 50px;
  margin: 10px 0 10px 35px;
  position: absolute !important;
  width: 55%;
  background: #002544;
  padding: 10px;
  border-radius: 12px;
  color: #fff;
  z-index: 9;
}

.search-align ul {
  margin-bottom: 5px !important;
  padding: 5px !important;
}

.search-align ul:hover {
  background-color: #5249ff !important;
  border-radius: 6px;
}

.search-align ul li a:hover {
  color: #e3e0e0 !important;
}

.search-align ul li {
  list-style: none;
}

.carousel-item img {
  height: calc(100vh - 175px);
  object-fit: cover;
}

.header-topbar ul {
  margin-bottom: 0 !important;
}

.features-products-wrap {
  margin: 67px 0;
}

.features-product-bottom {
  display: flex;
  gap: 7px;
  margin-top: 0px;
  justify-content: center;
  margin-bottom: 0px;
}

.features-product-content {
  background: #fff;
  padding: 16px 12px;
  border-radius: 0px;
  box-shadow: 0 0 59px #f1f1f1;
  transition: all 0.4s ease;
  margin: 0px 16px;
}

/* .features-product-content p {
  margin: 32px 0;
} */
.main-img img {
  width: 80% !important;
  margin: auto !important;
  height: 100px !important;
  object-fit: scale-down;
}

.features-product-content h2.title {
  font-weight: bold;
  font-size: 15px;
  margin-top: 0px;
  text-align: center;
  padding: 0 20px;
}

.features-product-content p {
  margin: -17px 0 0px;
  text-align: center;
  font-size: 16px;
}

.features-product-content:hover {
  transform: scale(1.05);
  border: 2px solid #0cb8b6;
  border-radius: 15px;
}

p.card_content_2 {
  font-size: 13px;
  padding: 10px 10px;
}

.st-btn.st-style1.st-color1 {
  background-color: #0cb8b6;
  color: #fff;
  padding: 3px 10px;
  border-radius: 4px;
  border: #0cb8b6 2px solid;
  font-weight: 600;
  transition: all 0.45s;
}

.main-img {
  text-align: center;
}

.title span {
  background: transparent;
}

button.btn.btn-primary:hover {
  background-color: #499afa !important;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 1px 1px 7px 5px #499afa !important;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}

.rs-carousel-slider-item {
  object-fit: cover;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  margin: 5px 0px;
}

.custom-slider.rs-carousel.rs-carousel-placement-bottom.rs-carousel-shape-dot {
  height: calc(100vh - 152.58px);
}
.slider_image_main{

  height: 100%;
  width: 100%;
}

a.head-size {
  font-size: 20px;
}

.icon_circular_main {
  width: 80px;
  height: 80px;
  background-color: #0f0b59 !important;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto 10px;
}

/* Buttons */
.button-align {
  padding: 10px 0 35px 0;
  text-align: center;
}

/* Buttons */

.button-align-center {
  text-align: center;
}

li.active.categories-padding.fs-3.fw-bolder.list-group-item {
  padding-top: 20px !important;
}

li.active.categories-padding.fs-3.fw-bolder.list-group-item {
  padding-top: 20px !important;
  background-color: #0cb8b6 !important;
  border: #0cb8b6 !important;
}

ul#menu-\:r5\: li a {
  font-weight: 600;
}

button.btn.btn-primary.post-align {
  border: #0cb8b6 !important;
  background-color: #0cb8b6 !important;
  transition: all 0.45s;
}

button.st-btn.st-style1.st-size-large.st-color1:hover,
a.st-btn.st-style1.st-size-large.st-color1:hover {
  background-color: #038a87 !important;
  border: 2px solid #038a87 !important;
}

button.btn.btn-primary.post-align:hover {
  background-color: #038a87 !important;
  box-shadow: none !important;
}

.input-group.w-75.me-3.input-function {
  border-radius: 20px;
}

button.btn.btn-primary.submit-btn {
  background-color: #0cb8b6;
  border: #0cb8b6;
  margin-top: 10px;
  width: 9rem;
}

/* Blogs */

.st-post.st-style3 {
  box-shadow: 6px 6px 14px 5px #00000014 !important;
  border-radius: 14px !important;
}

.st-post.st-style3 .st-post-thumb {
  display: block;
  margin-bottom: 25px px;
  overflow: hidden;
  position: relative;
}

.st-zoom {
  position: relative;
  overflow: hidden;
}

.st-post.st-style3 .st-post-thumb img {
  width: 100%;
}

.st-zoom .st-zoom-in {
  transition: all 0.4s ease-in-out;
}

.st-link-hover-wrap .st-link-hover {
  position: absolute;
  z-index: 2;
  top: -100px;
  right: 20px;
  width: 50px;
  height: 120px;
  background: #0cb8b6;
  border-radius: 0 0 40px 40px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}

.st-post.st-style3.st-color1 .st-link-hover-wrap .st-link-hover {
  background-color: #27b1d9;
}

.st-post.st-style3.st-color1 .st-link-hover-wrap .st-link-hover i,
.st-post.st-style3.st-color1 a:not(.st-btn):hover {
  color: #27b1d9;
}

.st-post.st-style3.st-color2 .st-link-hover-wrap .st-link-hover {
  background-color: #ff375f;
}

.st-post.st-style3.st-color2 .st-link-hover-wrap .st-link-hover i,
.st-post.st-style3.st-color2 a:not(.st-btn):hover {
  color: #ff375f;
}

.rtl .st-link-hover-wrap .st-link-hover {
  right: initial;
  left: 20px;
}

.st-post.st-style3 a:hover {
  color: #0cb8b6;
}

.st-link-hover-wrap .st-link-hover i {
  color: #0cb8b6;
  background-color: #fff;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 5px;
  opacity: 0;
  transform: scale(0.5) rotate(180deg);
  transition: all 0.3s ease;
}

.st-link-hover-wrap:hover .st-link-hover {
  opacity: 1;
  top: 0px;
}

.st-link-hover-wrap:hover .st-link-hover i {
  opacity: 1;
  transform: scale(1) rotate(0deg);
  transition-delay: 0.15s;
}

.st-post.st-style3 .st-post-thumb:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #111;
  opacity: 0;
  left: 0;
  top: 0;
  transition: all 0.3s ease;
}

.st-post.st-style3 a:hover {
  color: #0cb8b6;
}

.st-post.st-style3 .st-post-thumb {
  display: block;
  margin-bottom: 0px;

  overflow: hidden;
  position: relative;
}

/* Blogs */

/* Common styles for all screen sizes */
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* Use min-height instead of height for better responsiveness */
  background-image: url("https://t4.ftcdn.net/jpg/02/68/80/71/240_F_268807166_oPVMMlJcxy4OjBDkl9JPxqfJeMC98yNm.jpg");
  background-size: cover;
  background-position: center;
}

.error-page .container {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeInUp 1s ease-in-out;
}

.error-page .container h1 {
  font-size: 6rem;
  margin-bottom: 20px;
}

.error-page .container h3 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.error-page .container p {
  max-width: 400px;
  margin-bottom: 30px;
  text-align: center;
}

.error-page .container a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.img-abt img {
  height: 400px;
  width: 100%;
}

.col-12.col-md-6.center2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

img.about-capsule_an {
  position: absolute;
  height: 150px;
  width: 170px;
  z-index: -1;
  opacity: 0.9;
}

span.icon_circular_main.rounded-circle.d-block:hover {
  transform: scale(1.2);
  transition: 0.3s;
  animation: all 2s ease infinite;
}

/* span.icon_circular_main.rounded-circle.d-block {
  animation: all 2s ease infinite;
}
@keyframes all {
  10% {
    margin-top: 0px;
  }
  20% {
    margin-top: 1px;
  }
  30% {
    margin-top: 2px;
  }
  40% {
    margin-top: 3px;
  }
  50% {
    margin-top: 4px;
  }
  60% {
    margin-top: 3px;
  }
  70% {
    margin-top: 2px;
  }
  80% {
    margin-top: 1px;
  }
  90% {
    margin-top: 0px;
  }
  100% {
    margin-top: 1px;
  }
} */
a.comp-detail_54 {
  text-decoration: none;
  color: white;
  padding: 10px 21px !important;
}

.search-align ul li a {
  color: white !important;
  text-decoration: none !important;
}

.error-page .container a:hover {
  background-color: #0056b3;
}

.error-icon {
  font-size: 100px;
  margin-bottom: 40px;
}

button.border-color.btn.btn-outline-primary {
  border: 1px #0cb8b6 solid;
  color: #0cb8b6;
}

.button-align1 {
  width: 163%;
  padding-top: 0px !important;
  text-align: center !important;
}

.blog_desc_1:first-letter {
  text-transform: uppercase;
}

/* Responsive styles using media queries */
@media screen and (max-width: 1200px) {
  .search-align {
    left: 40px;
    width: 85%;
  }

  .gap2 {
    margin-top: -40px;
  }
}

@media screen and (max-width: 991px) {
  .gap2 {
    margin-top: 10px !important;
  }

  /* .slid-img1 img {
    height: 100%;
  } */
}

/* For screens with a width of 768 pixels or less (e.g., tablets and mobile phones) */
@media screen and (max-width: 768px) {
  .error-page .container {
    padding: 20px;
  }

  .custom-slider.rs-carousel.rs-carousel-placement-bottom.rs-carousel-shape-dot {
    height: 225px !important;
  }

  .carousel_item_45 {
    height: 225px;
  }

  .cate-head-respons {
    width: 100%;
  }

  /* .slid-img1 img {
    height: 100% !important;
} */
  .button-align1 {
    padding-top: 0px !important;
    text-align: left !important;
    padding-left: 150px;
  }

  .error-page .container h1 {
    font-size: 4rem;
  }

  .error-page .container h3 {
    font-size: 1.5rem;
  }

  .error-page .container p {
    max-width: 100%;
  }

  .error-page .container a {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .error-page .error-icon {
    font-size: 80px;
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .m-sm-2 {
    margin-top: 15px !important;
  }

  /* .slid-img1 img {
    height: 100% !important;
} */
}

/* 
nav.navbar.navbar-expand-lg.bg-body-tertiary.py-4.shadow.fixed-top {
  overflow-x: hidden;
} */

/* For screens with a width of 576 pixels or less (e.g., mobile phones) */
@media screen and (max-width: 576px) {
  .error-page .container h1 {
    font-size: 3rem;
  }

  .category1 {
    height: auto !important;
  }

  .slid-img1 img {
    height: 90% !important;
  }

  .error-page .container p {
    font-size: 0.8rem;
  }

  .error-page .error-icon {
    font-size: 60px;
  }
}

.margin-top {
  margin-top: 130px;
}

form.d-flex.justify-content-center.px-5 {
  margin-top: 22px !important;
}

.button-align1 {
  padding-top: 0px !important;
  text-align: center !important;
}

.st-post-meta {
  padding: 10px 8px;
}

button.st-btn.st-style2.st-color1.st-size-medium {
  background-color: #fff;
  border-color: #0cb8b6;
  border-radius: 5px;
  padding: 5px 14px;
  color: #0cb8b6;
  border: 1px solid;
}

.st-post-footer {
  padding: 20px 20px !important;
  background: white;
  text-align: center;
}

header li {
  list-style-type: none !important;
}

.st-post-text {
  padding: 0px 8px;
}

.d-flex.justify-content-center.innerBlog img {
  height: 500px;
  object-fit: cover;
  width: 100%;
  object-position: 0 17%;
}

h2.st-post-title {
  padding-bottom: 0 !important;
}

button.st-btn.st-style2.st-color1.st-size-medium:hover {
  background-color: #0cb8b6;
  color: #ffff;
}

.template-header .header-topbar .topbar-menu li::before {
  display: none;
}

.about_image img {
  width: 100%;
}

.collapse.navbar-collapse {
  padding-left: 0px !important;
}

button.btn.btn-primary.post-align {
  border: #0cb8b6 !important;
  background-color: #0cb8b6 !important;
  font-size: 16px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 10px 42px 10px 42px;
  font-weight: 600;
}

.allCategories {
  position: absolute;
  background: #0f0b59;
  width: 75%;
  height: 100%;
  top: 0;
  left: 25%;
  opacity: 0.8;
  z-index: 9999;
  overflow: auto;
  transition: 0.8s ease;
}

.allCategories li:hover {
  background: #0cb8b6 !important;
  color: white;
  border-radius: 5px;
  transition: 0.3s;
}

.allCategories li {
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 10px;
  margin: 10px;
  list-style-type: none;
  cursor: pointer;
}

.footer_bg_225 {
  background-color: #deeef3 !important;
  padding: 1px;
  background-image: url("../images//19366.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 5px 0px 25px 14px #edecec; */
}

.category2 {
  position: absolute;
  top: 0;
  left: 24.5%;
  z-index: 9999;
  width: 100%;
  height: 472px;
  overflow-y: auto;
}

/* form.d-flex.form-alignment.justify-content-center.px-5 {
  position: relative;
  left: -118px;
  top: 19px;
}

button.btn.btn-primary.post-align {
  position: relative !important;
  top: -20px !important;
  left: 106px !important;
}
form.d-flex.justify-content-center.px-5 {
  position: relative !important;
  right: 99px !important;
  top: 20px !important;
} */

.slick-slide img {
  display: block;
  width: 100% !important;
  height: 120px !important;
  cursor: pointer;
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .button-align1 {
    padding-top: 0px !important;
    text-align: left !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center;
  }

  .search-align {
    top: 40px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  button.btn.btn-primary.post-align {
    border: #0cb8b6 !important;
    background-color: #0cb8b6 !important;
    font-size: 13px;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 10px 20px 10px 20px;
    display: flex;
  }

  .search-align {
    top: 80px;
  }
}

@media screen and (max-width: 575px) {
  .button-align1 {
    text-align: center !important;
    padding-top: 14px !important;
    text-align: left !important;
    padding-left: 0px;
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .rs-dropdown {
    margin-left: 0rem !important;
  }

  .search-align {
    top: 80px;
  }
}

.fw-card {
  font-size: 13px !important;
  font-style: revert !important;
  padding: 11px 0px;
}

@media screen and (max-width: 475px) {
  .search-align {
    left: -30px;
    width: 92%;
  }

  .search-align {
    top: 80px;
  }

  button.btn.btn-primary.post-align {
    padding: 6px 15px !important;
  }
}

/*======= Header =======*/

:root {
  --color-primary: #2e279d;
  --color-primary-07: rgba(46, 39, 157, 0.07);
  --color-primary-10: rgba(46, 39, 157, 0.1);
  --color-primary-20: rgba(46, 39, 157, 0.2);
  --color-secondary: #499afa;
  --color-secondary-10: rgba(73, 154, 250, 0.1);
  --color-tertiary: #21cdc0;
  --color-fourth: #213360;
  --color-heading: #0e204d;
  --color-heading-07: rgba(14, 32, 77, 0.07);
  --color-heading-10: rgba(14, 32, 77, 0.1);
  --color-heading-50: rgba(14, 32, 77, 0.5);
  --color-body: #4d6581;
  --color-grey: #f3f5f8;
  --color-white: #fff;
  --color-black: #000000;
  --font-inter: "Inter", sans-serif;
  --font-lora: "Lora", serif;
}

.template-header {
  position: relative;
  z-index: 999;
}

.template-header .header-navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 1199px) {
  .template-header .header-navigation {
    padding: 18px 0;
  }
}

.template-header .header-left,
.template-header .header-center,
.template-header .header-right {
  padding-left: 15px;
  padding-right: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.template-header .extra-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.template-header .extra-icons li:not(:first-child) {
  margin-left: 30px;
}

.template-header .header-center .extra-icons li {
  margin-left: 30px;
}

@media (max-width: 1299px) {
  .template-header .header-center .extra-icons li {
    margin-left: 20px;
  }
}

.template-header .header-center .extra-icons li:first-child {
  margin-left: 15px;
}

@media (max-width: 424px) {
  .template-header .site-logo {
    max-width: 150px;
  }
}

.template-header .site-menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.template-header .site-menu ul li {
  display: block;
  line-height: 110px;
  position: relative;
  z-index: 1;
  padding: 0 8px;
}

@media (max-width: 1299px) {
  .template-header .site-menu ul li {
    padding: 0px 5px;
  }
}

.template-header .site-menu ul li a {
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-inter);
  line-height: 1.4;
  color: var(--color-heading);
}

.template-header .site-menu ul li a .dd-trigger {
  font-size: 12px;
  padding-left: 5px;
}

.template-header .site-menu ul li a:hover {
  color: var(--color-secondary);
}

.template-header .site-menu ul li:hover>.sub-menu {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.template-header .site-menu ul li .sub-menu {
  position: absolute;
  display: block;
  position: absolute;
  width: 220px;
  background-color: var(--color-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 29, 35, 0.05);
  box-shadow: 0 2px 8px 0 rgba(0, 29, 35, 0.05);
  left: 0;
  top: calc(100% + 40px);
  visibility: hidden;
  opacity: 0;
}

.template-header .site-menu ul li .sub-menu li {
  line-height: 1;
  padding: 0;
}

.template-header .site-menu ul li .sub-menu li:not(:last-child) {
  border-bottom: 1px solid var(--color-heading-07);
}

.template-header .site-menu ul li .sub-menu li a {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 15px;
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  line-height: 1.4;
  color: var(--color-body);
}

.template-header .site-menu ul li .sub-menu li a:hover {
  background-color: var(--color-secondary);
  color: var(--color-white);
}

.template-header .site-menu ul li .sub-menu li a::before {
  display: none;
}

.template-header .site-menu ul li .sub-menu li .sub-menu {
  left: 100%;
  top: 50%;
}

.template-header .site-menu ul li .sub-menu li:hover .sub-menu {
  top: 0;
}

.template-header .site-menu ul li.active a {
  color: var(--color-secondary);
}

.template-header .site-menu.item-extra-gap ul li {
  padding: 0 15px;
}

@media (max-width: 1599px) {
  .template-header .site-menu.item-extra-gap ul li {
    padding: 0 10px;
  }
}

@media (max-width: 1299px) {
  .template-header .site-menu.item-extra-gap ul li {
    padding: 0px 8px;
  }
}

.template-header .site-menu.menu-gap-left {
  margin-left: 90px;
}

.template-header .site-menu.item-left ul.primary-menu>li:first-child {
  padding-left: 0;
}

.template-header .site-menu.item-left ul.primary-menu>li:first-child>a {
  padding-left: 0;
}

.template-header .header-search-area form {
  position: relative;
  z-index: 1;
}

.template-header .header-search-area input {
  width: 280px;
  height: 55px;
  border-radius: 30px;
  border: 2px solid rgba(77, 101, 129, 0.15);
  background-color: var(--color-white);
  font-size: 15px;
  font-weight: 500;
  color: var(--color-heading);
  padding: 0 45px 0 25px;
}

.template-header .header-search-area input:focus {
  border-color: var(--color-heading);
}

@media (max-width: 1299px) {
  .template-header .header-search-area input {
    width: 220px;
  }
}

@media (max-width: 767px) {
  .template-header .header-search-area input {
    width: 200px;
  }
}

.template-header .header-search-area button {
  position: absolute;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--color-heading);
  background-color: transparent;
  font-size: 15px;
  line-height: 1;
}

.template-header .off-canvas-btn {
  position: relative;
  cursor: pointer;
  padding: 10px 0;
}

.template-header .off-canvas-btn span {
  width: 35px;
  height: 4px;
  display: block;
}

.template-header .off-canvas-btn span::before,
.template-header .off-canvas-btn span::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 35px;
  height: 4px;
  display: block;
}

.template-header .off-canvas-btn span::after {
  top: auto;
  bottom: 0;
}

.template-header .off-canvas-btn span,
.template-header .off-canvas-btn span::before,
.template-header .off-canvas-btn span::after {
  background-color: var(--color-secondary);
}

.template-header .off-canvas-btn.style-two {
  padding: 13px 0;
}

.template-header .off-canvas-btn.style-two span {
  width: 50px;
}

.template-header .off-canvas-btn.style-two span,
.template-header .off-canvas-btn.style-two span::before,
.template-header .off-canvas-btn.style-two span::after {
  background-color: var(--color-primary);
}

.template-header .off-canvas-btn.style-two span::before {
  width: 45px;
}

.template-header .off-canvas-btn.style-two span::after {
  width: 40px;
}

.template-header .search-btn {
  display: block;
  line-height: 1;
  color: var(--color-heading);
  font-size: 15px;
}

.template-header .cart-icon,
.template-header .wishlist-icon {
  display: block;
  font-size: 22px;
  line-height: 1;
  color: var(--color-heading);
}

.template-header .cart-icon:hover,
.template-header .wishlist-icon:hover {
  color: var(--color-secondary);
}

.template-header .navbar-toggler {
  display: block;
  width: 55px;
  padding: 10px;
  font-size: 0;
  border: 2px solid var(--color-heading);
  border-radius: 5px;
}

.template-header .navbar-toggler span {
  display: block;
  height: 3px;
  background-color: var(--color-heading);
}

.template-header .navbar-toggler span:nth-child(2) {
  margin: 6px 0;
}

.template-header .navigation-white-color .site-menu ul li a {
  color: var(--color-white);
}

.template-header .navigation-white-color .cart-icon,
.template-header .navigation-white-color .wishlist-icon {
  color: var(--color-white);
}

.template-header .navigation-white-color .off-canvas-btn span,
.template-header .navigation-white-color .off-canvas-btn span::before,
.template-header .navigation-white-color .off-canvas-btn span::after {
  background-color: var(--color-white);
}

.template-header .navigation-white-color .navbar-toggler {
  border-color: var(--color-white);
}

.template-header .navigation-white-color .navbar-toggler span {
  background-color: var(--color-white);
}

.template-header .header-top-list-one {
  background-color: var(--color-primary);
}

.template-header .header-top-list-one .list-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.template-header .header-top-list-one .list-items .single-list-item {
  padding: 25px 0;
  padding-right: 90px;
  margin-right: 90px;
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.template-header .header-top-list-one .list-items .single-list-item:last-child {
  margin-right: 0;
}

@media (max-width: 1199px) {
  .template-header .header-top-list-one .list-items .single-list-item {
    padding-right: 65px;
    margin-right: 65px;
  }
}

.template-header .header-top-list-one .list-items .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

@media (max-width: 991px) {
  .template-header .header-top-list-one .list-items .contact-info {
    display: none;
  }
}

.template-header .header-top-list-one .list-items .contact-info,
.template-header .header-top-list-one .list-items .contact-info a {
  color: var(--color-white);
}

.template-header .header-top-list-one .list-items .contact-info .icon {
  margin-right: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.template-header .header-top-list-one .list-items .contact-info .icon img {
  max-width: 50px;
}

.template-header .header-top-list-one .list-items .contact-info .info-title {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-inter);
}

@media (max-width: 1299px) {
  .template-header .header-top-list-one .list-items .contact-info .info-title {
    font-size: 14px;
  }
}

.template-header .header-top-list-one .list-items .contact-info .info-desc {
  font-size: 22px;
  font-weight: 400;
  font-family: var(--font-lora);
}

@media (max-width: 1299px) {
  .template-header .header-top-list-one .list-items .contact-info .info-desc {
    font-size: 18px;
  }
}

.template-header .header-top-list-two {
  border-bottom: 1px solid var(--color-heading-10);
}

.template-header .header-top-list-two .list-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.template-header .header-top-list-two .list-items .single-list-item {
  border-right: 1px solid var(--color-heading-10);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 70px;
}

@media (max-width: 1299px) {
  .template-header .header-top-list-two .list-items .single-list-item {
    padding: 25px 45px;
  }
}

@media (max-width: 1199px) {
  .template-header .header-top-list-two .list-items .single-list-item {
    padding: 25px 35px;
  }
}

.template-header .header-top-list-two .list-items .single-list-item:last-child {
  margin-right: 0;
}

.template-header .header-top-list-two .list-items .single-list-item:first-child {
  border-left: 1px solid var(--color-heading-10);
}

.template-header .header-top-list-two .list-items .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.template-header .header-top-list-two .list-items .contact-info,
.template-header .header-top-list-two .list-items .contact-info a {
  color: var(--color-heading);
}

.template-header .header-top-list-two .list-items .contact-info .icon {
  margin-right: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50px;
  flex: 0 0 50px;
}

.template-header .header-top-list-two .list-items .contact-info .icon img {
  max-width: 50px;
}

.template-header .header-top-list-two .list-items .contact-info .info-title {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-inter);
}

@media (max-width: 1299px) {
  .template-header .header-top-list-two .list-items .contact-info .info-title {
    font-size: 14px;
  }
}

.template-header .header-top-list-two .list-items .contact-info .info-desc {
  font-size: 22px;
  font-weight: 400;
  font-family: var(--font-lora);
}

@media (max-width: 1299px) {
  .template-header .header-top-list-two .list-items .contact-info .info-desc {
    font-size: 18px;
  }
}

.template-header .header-top-note {
  background-color: var(--color-primary);
  text-align: center;
  padding: 8px 0;
}

@media (max-width: 991px) {
  .template-header .header-top-note {
    font-size: 14px;
  }
}

@media (max-width: 424px) {
  .template-header .header-top-note {
    display: none;
  }
}

.template-header .header-top-note,
.template-header .header-top-note a {
  color: var(--color-white);
}

.template-header .header-top-note a {
  margin-left: 15px;
}

.template-header .header-top-note a i {
  margin-right: 8px;
}

.template-header .header-topbar {
  background-color: #0f0b59 !important;
  padding: 15px 0;
}

.template-header .header-topbar,
.template-header .header-topbar a {
  color: var(--color-white);
}

.template-header .header-topbar .topbar-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .template-header .header-topbar .topbar-menu {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.template-header .header-topbar .topbar-menu li {
  padding-left: 15px;
  padding-right: 20px;
  position: relative;
  line-height: 1.4;
}

@media (max-width: 1199px) {
  .template-header .header-topbar .topbar-menu li {
    font-size: 15px;
  }
}

.template-header .header-topbar .topbar-menu li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 5px;
  width: 5px;
  background-color: var(--color-white);
  border-radius: 50%;
}

.template-header .header-topbar .topbar-menu li a:hover {
  text-decoration: underline;
}

.template-header .header-topbar .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .template-header .header-topbar .contact-info {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 15px;
  }
}

.template-header .header-topbar .contact-info li {
  padding-left: 25px;
  padding-right: 25px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  line-height: 1.4;
}

@media (max-width: 1199px) {
  .template-header .header-topbar .contact-info li {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .template-header .header-topbar .contact-info li:first-child {
    border-left: none;
  }
}

.template-header .header-topbar .contact-info li:last-child {
  padding-right: 0;
}

.template-header .header-topbar .contact-info li i {
  margin-right: 5px;
}

.template-header .header-topbar .contact-info li .social-icons a {
  opacity: 1;
}

.template-header .header-topbar .contact-info li .social-icons a:not(:first-child) {
  margin-left: 20px;
}

@media (max-width: 1199px) {
  .template-header .header-topbar .contact-info li .social-icons a:not(:first-child) {
    margin-left: 16px;
  }
}

.template-header .header-topbar .contact-info li .social-icons a:hover {
  opacity: 0.8;
}

.template-header .header-topbar .contact-info li .social-icons a i {
  margin: 0;
}

.template-header.absolute-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  background-color: transparent;
}

.template-header.sticky-header.sticky-on {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-animation: sticky 1.2s;
  animation: sticky 1.2s;
  -webkit-box-shadow: 0 8px 20px rgba(14, 32, 77, 0.08);
  box-shadow: 0 8px 20px rgba(14, 32, 77, 0.08);
  background-color: var(--color-white);
}

.template-header.sticky-header.sticky-on .site-menu ul li {
  line-height: 100px;
}

.template-header.sticky-header.sticky-on .header-topbar,
.template-header.sticky-header.sticky-on .header-top-list-two,
.template-header.sticky-header.sticky-on .header-top-note {
  display: none !important;
}

.template-header.sticky-header.sticky-on.sticky-secondary-bg {
  background-color: var(--color-heading);
}

@media (min-width: 992px) {
  .template-header.header-three .header-navigation {
    padding: 0;
  }
}

.template-header.header-five .header-navigation .site-menu ul li .sub-menu li a {
  color: var(--color-heading);
}

.template-header.header-five .header-navigation .site-menu ul li .sub-menu li a:hover {
  background-color: var(--color-tertiary);
  color: var(--color-white);
}

.template-header.header-five .header-navigation .site-menu ul li a:hover,
.template-header.header-five .header-navigation .site-menu ul li.active a {
  color: var(--color-tertiary);
}

.hero-area-one {
  padding-top: 100px !important;
}

.blog-loop .single-blog-post .post-meta li button {
  font-size: 16px;
  line-height: 1;
  display: block;
  color: var(--color-body);
  background: transparent;
}

.details-blog:hover {
  color: black !important;
}

.blog-area .m-5 button {
  background: transparent !important;
}

.widget.category-widget li {
  margin-bottom: 5px !important;
  cursor: pointer;
}

.primary-sidebar .widget.category-widget ul li {
  display: block;
  color: var(--color-body);
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px 0;
}

.primary-sidebar .widget.category-widget ul li:hover {
  background-color: gray;
  padding: 5px;
  transition: 0.5s;
  color: white;
}

.post-link:hover {
  background-color: #2e279d !important;
}

.widget.category-widget li:after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "\2192";
  font-family: Font Awesome\5 Pro;
  font-weight: 400;
  font-size: 15px;
}

.primary-sidebar .widget:not(:last-child) {
  margin-bottom: 0px !important;
}

.template-header .site-menu.menu-gap-left {
  margin-left: 200px !important;
}

.hero-area-one::before {
  top: -100px !important;
}

.slick-prev,
.slick-next {
  display: none !important;
}

/* .slick-list img {
  height: 425px;
  object-fit: contain;
} */
.section-gap {
  padding-top: 0px !important;
  padding-bottom: 130px;
}

.iconic-box-two .title {
  font-size: 20px !important;
}

.iconic-box-two {
  padding: 20px !important;
  min-height: 400px;
  line-height: 1.5 !important;
}

.title-resize {
  font-size: 45px;
  width: 80%;
  margin: 0 auto;
  line-height: 1.2;
}

.section-heading .tagline {
  line-height: 1.5 !important;
}

.slick-slide>div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

img.rounded.slider2-imgs {
  min-height: 300px;
  width: 100%;
  height: 100%;
}

.section-heading .title {
  font-size: 30px !important;
  line-height: 1.35;
  font-weight: 600;
}

.row.gallery-loop.gallery-filter-item {
  height: 800px !important;
}

.wcu-section-two .container-fluid {
  margin: 80px 0 25px 0 !important;
  padding: 25px 0 !important;
}

li.list-group-item {
  background: transparent;
  color: white;
  border: none;
  font-weight: 600;
}

h1.owrks-padding.py-4 {
  font-weight: 600;
}

li.list-group-item:hover {
  color: white !important;
  padding-left: 30px !important;
  transition: 0.5s !important;
  background: #0cb8b6 !important;
}

.carousel-caption.d-none.d-md-block {
  background: rgba(0, 0, 0, 0.5);
}

.carousel-caption h5 {
  font-weight: 700;
  color: white;
}

/* .bg-img:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../img/polygen.png');
    background-position: 50%;
    background-size: cover;
	opacity: 0.04 !important;
} */
.section-gap1 {
  padding-bottom: 50px;
}

.counter-inner-two {
  padding: 40px 0 40px !important;
}

.wcu-section-two .container-fluid {
  background-color: transparent !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

h6.title.text-center.resize2 {
  font-size: 24px;
  font-weight: 600;
}

body {
  overflow-x: hidden !important;
}

.hero-area-one {
  padding-bottom: 0px !important;
}

.wcu-section::before {
  display: none !important;
}

.wcu-section {
  background-color: #2e279d;
}

.counter-inner-two::before {
  display: none !important;
}

li.ctg {
  padding: 8px;
  cursor: pointer;
}

.product-loop-filter .dropdown button {
  background: transparent;
  color: black;
  border: 1px solid black;
}

li.ctg:hover {
  background: #f4f5f9;
}

.contact-info-wrapper {
  padding: 20px !important;
}

.single-product.mb-40:hover {
  transform: scale(1.1);
  transition: 0.6s;
}

.map1 iframe {
  width: 100%;
  height: 300px;
}

h6.name {
  font-size: 18px;
  font-weight: 600;
}

.contact-form .input-field textarea {
  width: 100% !important;
  height: 150px !important;
  color: var(--color-heading-50) !important;
  background-color: var(--color-grey) !important;
  border: 1px solid transparent !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: var(--font-inter) !important;
  border-radius: 0 !important;
  line-height: 1.4 !important;
  padding: 30px !important;
  -webkit-transition: all 0.3s ease-out 0s !important;
  -o-transition: all 0.3s ease-out 0s !important;
  transition: all 0.3s ease-out 0s !important;
}

.wcu-section-two .container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}

.counter-inner-two {
  padding: 0 !important;
}

textarea {
  background-color: var(--color-white) !important;
  border-radius: 5px !important;
  font-family: var(--font-lora) !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: var(--color-heading) !important;
}

.appointment-form-two .appointment-image {
  padding: 80px 65px 60px;
  background-color: transparent !important;
}

.section-heading.mb-40 p {
  color: #4d6581;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}

.footer-inner a,
h4 {
  color: white !important;
}

form.newsletters-form {
  position: relative;
}

form.newsletters-form input {
  border-radius: 15px;
  background: #2e279d;
  color: white;
}

.footer-inner a:hover {
  padding-left: 5px !important;
}

.check-list li::before {
  display: none !important;
}

span.tagline {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-secondary);
  line-height: 1.2;
  margin-bottom: 15px;
}

form.newsletters-form i.fa-solid.fa-arrow-right {
  position: absolute;
  right: 25px;
  top: 32px;
  color: white;
}

span.pr-3.rgt-arr:hover {
  padding-left: 5px;
}

.check-list li {
  padding-left: 0 !important;
}

.footer-inner {
  color: white !important;
}

.rs-modal-content {
  position: relative;
  background-color: #fff;
  background-color: var(--rs-bg-overlay);
  border: none;
  border: var(--rs-modal-border, none);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12),
    0 0 10px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--rs-modal-shadow);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  box-shadow: var(--rs-modal-shadow);
  padding: 20px;
  bottom: 13px;
}

.rs-modal-body {
  position: relative;
  margin-top: 20px;
  padding-bottom: 0px;
  max-height: 535px !important;
  overflow: auto !important;
}

@media (max-width: 991px) {
  .row.gallery-loop.gallery-filter-item {
    height: 1100px !important;
  }
}

@media (max-width: 768px) {
  .appointment-form-two .form-wrap {
    width: 80%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 0;
  }

  .iconic-box-two {
    min-height: 300px !important;
  }

  .appointment-form-two .appointment-image {
    padding: 10px !important;
    background-color: transparent !important;
  }

  .appointment-form-two .form-wrap {
    padding: 10px !important;
  }
}

@media (max-width: 575px) {
  .counter-inner-two::before {
    right: -15px !important;
  }

  .title-resize {
    font-size: 30px;
  }

  .appointment-image {
    padding: 10px !important;
  }

  .appointment-form-two .appointment-image {
    padding: 10px !important;
    background-color: transparent !important;
  }

  .appointment-form-two .form-wrap {
    padding: 10px !important;
  }

  .appointment-form-two .form-wrap {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  .section-heading .title {
    font-size: 22px !important;
  }

  textarea {
    font-size: 15px !important;
  }
}

@media (max-width: 425px) {
  .row.gallery-loop.gallery-filter-item {
    height: 600px !important;
  }
}

/************** NEW CSS END ***********/

/* .slider-image-container {
  max-width: 100%; /* Adjust the maximum width as needed 
  height: auto; 
} */

img.slider-img {
  object-fit: cover;
  width: 100% !important;
  height: calc(100vh - 100px) !important;
  margin: 0%;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

h1.about-padding.mt-5.text-center {
  font-size: 46px;
  margin-top: 30px !important;
  font-weight: bolder;
}

h2.blog-padding.st-section-heading-title {
  font-size: 46px;
  font-weight: bolder;
}

@media only screen and (max-width: 768px) {
  h2.blog-padding.st-section-heading-title {
    font-size: 30px;
    /* Adjusted text size for mobile devices */
  }
}

/* .company_bg-detail-55 {
  height: 100% !important;
} */
.dropdown {
  position: relative;
  display: inline-block;
  left: 56px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #002544;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  font-weight: 600;
}

.dropdown-content a {
  color: #ffffff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

button.dropbtn {
  background-color: transparent;
  color: #053d85;
  border-radius: 10px;
  font-weight: 700;
  display: flex;
  gap: 10px;
  align-items: center;
}

.input-group.w-75.me-3.input-function {
  border-radius: 20px;
  width: 55% !important;
}

.dropdown-content a:hover {
  background-color: #5249ff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: rgb(216, 213, 213);
}

.cardparent {
  height: auto;
  margin-bottom: 0px;
}

.rs-input-lg {
  padding: 9px 11px;
  font-size: 16px;
  line-height: 1.375;
  border: 1px solid #6fbff9;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #6fbff9;
}

section#about {
  height: 100%;
}

.slick-slider.slick-initialized {
  margin-top: 40px;
}

.slick-list img {
  height: 123px !important;
  object-fit: contain;
}

.blog_container {
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 28px 0px;
  gap: 5px;
  box-shadow: 1px 1px 9px 1px lightgrey;
}

.blog_heading_11 {
  font-size: 18px;
  color: #0c8dac;
}

.blog_desc_55 {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  color: #323232;
}

/*  */

.text_container_right {
  width: 100%;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 7px 7px;
  background-color: #f0f0f0;
}

.blog_date_top {
  font-size: 10px;
  text-align: right;
  color: #b049ff;
}

.carousel_item_45 {
  object-fit: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #fff;
}


.text_overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.705);
  padding: 26px 20px;
  bottom: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 19px;
}

.Comp_firstparent {
  height: 85vh;
}

img.company_image_5.img {
  width: 100%;
  border-radius: 27px;
  margin: 9px 12px;
  box-shadow: 1px 1px 15px 1px #a39a9a;
  height: 450px !important;
}

.comp_head_main {
  color: #757575;
  font-size: 50px;
  font-family: inherit;
  margin: 0px 0px !important;
  text-align: left;
}

.comp_desc_4 {
  color: #444444;
  font-size: 18px;
  font-family: inherit;
  padding: 10px 25px;
}

.detail_btn_1 {
  background-color: #007bff;
  color: #ffff;
  border-radius: 10px;
  padding: 10px 47px;
  margin: 10px 25px;
  font-size: 19px;
  border: 2px solid #57d0ff;
}

.detail_btn_1:hover {
  background-color: #0e204d;
  transition: all 0.11s ease-in-out;
  box-shadow: 1px 1px 7px 5px rgb(214, 212, 212);
  transition: box-shadow 0.3s ease-in-out;
}

/* .blog_image img{
  height: 100px;
  width: 100px;
  box-shadow: 1px 1px 5px 5px rgb(185, 185, 185);
} */

/* .float-area{
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: space-around;
} */

.floating-img {
  transform: translateY(-25%);
  animation: floater 2.5s infinite;
  transition: ease 0.8s;
}

.floating-img_22 {
  transform: translateY(-25%);
  animation: floater 2.5s infinite;
  transition: ease 0.8s;
}

@keyframes floater {
  0% {
    transform: translateY(-25%);
    transition: ease 0.8s;
  }

  50% {
    transform: translateY(25%);
    transition: ease 0.8s;
  }
}

.about-capsule_an_right {
  width: 190px;
  /* z-index: -1; */
  position: relative;
  opacity: 0.9;
  height: 200px !important;
  left: 600px;
  top: 250px;
}

.detail_btn_22 {
  background-color: transparent;
  color: #ffff;
  border-radius: 10px;
  padding: 10px 39px;
  margin: 13px 25px;
  font-size: 19px;
  border: 2px solid #57d0ff;
}

.detail_btn_22:hover {
  background-color: transparent;
  color: #ffff;
}

.company_bg-detail-55 {
  background-image: url("../images/15002.webp");
  /* height: 77vh; */
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}

.company-head_main_abc {
  color: #053d85;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 10px;
}

img.details_logo_1.img {
  transition: all 0.2s ease-in-out;
  width: 88%;
  border-radius: 5px;
}

img.details_logo_1.img:hover {
  border: 2px solid #ffffff;
  transform: scale(1.05);
  border-radius: 20px;
  box-shadow: 1px 1px 20px 1px #0000004d;
}

.something-about-company {
  color: rgb(0, 0, 0);
  font-size: 18px;
}

.address_comp_1 {
  color: rgb(0, 0, 0);
  font-size: 15px;
}

p.address_comp_1 span {
  color: #053d85;
  font-size: 25px;
}

.comp-detail_54 {
  transition: all 0.2s ease-in-out;
  background-color: #053d85;
  color: #ffff;
  border-radius: 10px;
  padding: 8px 21px;
  font-size: 19px;
  margin: 18px 0px 0px 10px;
}

.comp-detail_54:hover {
  background-color: #0e204d;
  box-shadow: 1px 1px 4px 3px rgb(214, 212, 212);
  transform: scale(1.03);
  border: 1px solid rgb(40, 170, 245);
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 40px 0px;
  margin: 0 0px;
  list-style: none;
  text-align: center;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 35px 0px;
  padding: 0;
}

h1.text-center.division_1 {
  font-size: 36px;
  margin-top: 34px !important;
}

.slider_head {
  text-align: center;
  margin: auto;
  font-size: 34px;
}

/* media_query_start */

@media (min-width: 0px) and (max-width: 767px) {
  .custom-slider.rs-carousel.rs-carousel-placement-bottom.rs-carousel-shape-dot {
    height: calc(53vh - 152.58px);
  }

  /* .category1 {
    height: 350px;
} */

  .input-group.w-75.me-3.input-function {
    width: 100% !important;
  }

  .aside {
    background: #0f0b59 !important;
  }

  h4.text_overlay {
    position: absolute;
    bottom: 0;
    width: 32% !important;
    line-height: 16px;
    height: 40px;
    font-size: 12px;
  }

  .dropdown {
    left: 0;
    right: 0;
  }

  .text_overlay {
    width: 27%;
  }

  .cardparent {
    height: 100%;
  }

  .features-product-content {
    width: 85vw;
  }

  section#blog {
    height: 100%;
  }

  .about-capsule_an_right {
    display: none;
  }

  .floating-img_22 {
    display: none;
  }

  .floating-img {
    display: none;
  }

  img.about-capsule_an {
    display: none;
  }

  .w-100 {
    margin-top: 27px;
    font-size: 28px !important;
  }

  .img-abt img {
    height: 317px;
  }

  .Comp_firstparent {
    height: 120vh;
  }

  img.company_image_5.img {
    height: 340px !important;
    width: 94%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .features-product-content {
    width: 37vw;
  }

  .custom-slider.rs-carousel.rs-carousel-placement-bottom.rs-carousel-shape-dot {
    height: calc(100vh - 152.58px) !important;
  }

  .carousel_item_45 {
    background-position-x: 100%;
  }

  section#blog {
    height: 100%;
  }

  .about-capsule_an_right {
    display: none;
  }

  .bg-img2 .list-group-item {
    margin: 0 !important;
  }

  .floating-img_22 {
    display: none;
  }

  .floating-img {
    display: none;
  }

  img.about-capsule_an {
    display: none;
  }

  section#about {
    height: 100%;
  }

  .img-abt img {
    height: 340px;
    margin-top: 35px;
  }

  .w-100 {
    width: 100% !important;
    margin-top: 33px;
    font-size: 29px !important;
  }

  .custom-slider.rs-carousel.rs-carousel-placement-bottom.rs-carousel-shape-dot {
    height: calc(66vh - 152.58px);
  }

  li.category-head.active.fw-bolder.fs-3.categories-padding {
    padding: 10px 18px !important;
  }

  /* .category1 {
    height: calc(66vh - 152.58px);
  } */
  .category1 {
    height: calc(100vh - 152.58px);
  }

  .text_overlay {
    width: 32%;
  }

  .cardparent {
    height: 100%;
  }
}

.slid-img-logos {
  background: white;
}

.category1::-webkit-scrollbar,
nav.navbar .rs-dropdown-menu::-webkit-scrollbar {
  background: gray;
  width: 10px;
}

.category1::-webkit-scrollbar-thumb,
nav.navbar .rs-dropdown-menu::-webkit-scrollbar-thumb {
  background: #0cb8b6;
  width: 10px;
  border-radius: 20px;
}

.categories-foot-a-chng p a {
  color: #212529bf;
  font-size: 14px;
}

nav.navbar .rs-dropdown-menu {
  height: 400px !important;
  overflow-y: auto;
}

.categories-foot-a-chng p a {
  color: #212529bf;
  font-size: 14px;
}

.rs-dropdown {
  margin-left: 2rem;
}

.bg-img2 .list-group a:hover {
  text-decoration: none !important;
}

.blog-btnUpdated {
  padding: 7px 12px;
  background: transparent;
  margin-left: 10px;
  border-radius: 5px;
  color: #575757 !important;
  /* border: 2px solid #0cb8b6; */
}

.blog-btnUpdated a {
  color: #575757 !important;
  text-decoration: none;
}

.cate-head-respons {
  height: calc(100vh - 152.58px);
  overflow-y: auto;
  overflow-x: hidden;
}

.cate-head-respons::-webkit-scrollbar {
  display: none;
}

.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  font-weight: 600;
}

.blog-btnUpdated a {
  font-weight: 600;
}

/* span#basic-addon2 {
  position: absolute;
  z-index: 9;
  right: 0;
} */
.input-group {
  border: 2px solid #0cb8b6;
  border-radius: 20px;
}

/* .company_bg-detail-55 {
  height: calc(80vh - 152.58px);
} */
.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  background: transparent !important;

  /* border: 2px solid #0cb8b6 !important; */
}

@media screen and (min-width: 992px) and (max-width: 1025px) {
  .btn-reduce-right {
    position: relative;
    right: 20px;
  }
}

@media screen and (min-width: 767.9px) and (max-width: 768.9px) {
  .cate-head-respons {
    width: 75%;
  }
}

ul.topbar-menu svg {
  margin-top: -1px;
}

.st-post-date {
  font-size: 10px;
  color: gray;
}

a.btn-refremerj {
  border: 1px solid #0cb8b6;
  padding: 6px 10px;
  border-radius: 5px;
  transition: all 0.4s;
  color: #0cb8b6 !important;
}

a.btn-refremerj:hover {
  border: 1px solid #0cb8b6;
  background-color: #0cb8b6;
  color: white !important;
  text-decoration: none;
}

.fw-normal {
  font-size: 13.5px;
  color: black !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
}

.categories-foot-a-chng p a {
  font-size: 13.5px !important;
  color: black !important;
  font-weight: 500 !important;
}

.one-line-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey;
}
.two-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey;
}
